import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, SpringConfig } from '../../../styles/index'
import InputFormGrid from './InputFormGrid'
import Button from '../../shared/Button'
import {animated, useSpring} from 'react-spring'
import { useCustomerAddress } from '../../../hooks/user/useCustomerAddress'
import { useCustomer, useGlobalDictionaryQuery, useLocale } from '../../../hooks'
import { navigate } from 'gatsby'


interface Props {
  handleBackToAddress: ()=>void
  handleUpdateCustomer: ()=>void
  addressToModify: {}
  newAddressMode: string
}

export default function NewAddressForm(props: Props) {

  const {
    handleBackToAddress,
    handleUpdateCustomer,
    newAddressMode,
    addressToModify,
  } = props
  
  const user = useCustomer()
  const dictionary = useGlobalDictionaryQuery()
  const locale = useLocale();

  const [
    { createAddress, updateAddress },
    { response, error },
  ] = useCustomerAddress()

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [company, setCompany] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('' )
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')

  const values = {
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    lastName,
    phone,
    zip,
  }

  const [containErrors, SetContainErrors] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [text, setText] = useState('')
  const [res, setRes] = useState(false)

  const softReset = ()=> {setClicked(false);  setText('');  setLoading(false)}

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true)
    }
    if (!state) {
      SetContainErrors(false)
    }
  }

  const submitAddress = e => {
    e.preventDefault()
    setLoading(true)
    if(newAddressMode.toLowerCase() === 'new'){
      createAddress(user.token, values).then(()=>{
        handleUpdateCustomer()
        setTimeout(() => {
          if (typeof window === "object") window.location.href = `/${locale}/account/?=my-address`;
        }, 1000);
      })
    }
    if(newAddressMode.toLowerCase() === 'modify'){
      updateAddress(user.token, addressToModify.id, values).then(()=>{
        handleUpdateCustomer()
        setTimeout(() => {
          if (typeof window === "object") window.location.href = `/${locale}/account/?=my-address`;
        }, 1000);
      })
    }
  }

  const buttonSpring = useSpring({
    transform: clicked ? 'translateY(120%)' : 'translateY(0%)',
    config: SpringConfig.microInteraction
  })

  const responseSpring = useSpring({
    transform: clicked ? 'translateY(0%)' : 'translateY(350%)',
    config: SpringConfig.microInteraction
  })

  useEffect(() => {
    if(newAddressMode && newAddressMode.toLowerCase() === 'modify'){
      setFirstName(addressToModify.firstName ? addressToModify.firstName : '')
      setLastName(addressToModify.lastName ? addressToModify.lastName : '')
      setEmail(user.email ? user.email : '')
      setAddress1(addressToModify.address1 ? addressToModify.address1 : '')
      setZip(addressToModify.zip ? addressToModify.zip : '')
      setCity(addressToModify.city ? addressToModify.city : '')
      setPhone(addressToModify.phone ? addressToModify.phone : '')
      setCountry(addressToModify.country ? addressToModify.country : '')
    }
  }, [])

  // useEffect(() => {
  //   if(response && typeof response !== "undefined"){
  //     console.log(response)
  //   }
  //   if(error && typeof error !== 'undefined'){
  //     console.log(error)
  //     setText(error)
  //     setClicked(true)
  //     setTimeout(() => {
  //       softReset()
  //     }, 2000);
  //   }
  // }, [response, error])

  // useEffect(() => {
  //   if(error && !clicked){
  //     setClicked(true)
  //     setText(error)
  //   }
  //   if (response) {
  //     console.log(response)
  //   }
  // }, [response, error])

  return (
    <form
      onSubmit={e=>submitAddress(e)}
    >
    <Wrapper>
        <div
          className="newAddress__firstName centerContent"
        >
          <InputFormGrid
            inputValue={firstName}
            inputSetValue={setFirstName}
            inputName='firstName'
            inputType='text'
            required={true}
            onChangecallback={e => setFirstName(e.target.value)}
            placeholder={dictionary.firstName}
            color={colors.black}
            width={'100%'}
            widthDesk={'half'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'onlyLetters'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__lastName centerContent"
        >
          <InputFormGrid
            inputValue={lastName}
            inputSetValue={setLastName}
            inputName='lastName'
            inputType='text'
            required={true}
            onChangecallback={e => setLastName(e.target.value)}
            placeholder={dictionary.lastName}
            color={colors.black}
            width={'100%'}
            widthDesk={'half'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'onlyLetters'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__emailAddress centerContent"
        >
          <InputFormGrid
            inputValue={email}
            inputSetValue={setEmail}
            inputName='email'
            inputType='text'
            required={true}
            onChangecallback={e => setEmail(e.target.value)}
            placeholder={dictionary.emailAddress}
            color={colors.black}
            width={'100%'}
            widthDesk={'full'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'onlyEmail'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__address centerContent"
        >
          <InputFormGrid
            inputValue={address1}
            inputSetValue={setAddress1}
            inputName='address'
            inputType='text'
            required={true}
            onChangecallback={e => setAddress1(e.target.value)}
            placeholder={dictionary.address}
            color={colors.black}
            width={'100%'}
            widthDesk={'full'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'noValidation'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__zipCode centerContent"
        >
          <InputFormGrid
            inputValue={zip}
            inputSetValue={setZip}
            inputName='zipCode'
            inputType='text'
            required={true}
            onChangecallback={e => setZip(e.target.value)}
            placeholder={dictionary.zipCode}
            color={colors.black}
            width={'100%'}
            widthDesk={'half'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'noValidation'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__city centerContent"
        >
          <InputFormGrid
            inputValue={city}
            inputSetValue={setCity}
            inputName='city'
            inputType='text'
            required={true}
            onChangecallback={e => setCity(e.target.value)}
            placeholder={dictionary.city}
            color={colors.black}
            width={'100%'}
            widthDesk={'half'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'noValidation'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__phone centerContent"
        >
          <InputFormGrid
            inputValue={phone}
            inputSetValue={setPhone}
            inputName='phone'
            inputType='text'
            required={true}
            onChangecallback={e => setPhone(e.target.value)}
            placeholder={dictionary.phoneNumber}
            color={colors.black}
            width={'100%'}
            widthDesk={'half'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'onlyNumbers'}
            setContainErrors={handleContainerrors}
          />
        </div>
        <div
          className="newAddress__country centerContent"
        >
          <InputFormGrid
            inputValue={country}
            inputSetValue={setCountry}
            inputName='country'
            inputType='text'
            required={true}
            onChangecallback={e => setCountry(e.target.value)}
            placeholder={dictionary.country}
            color={colors.black}
            width={'100%'}
            widthDesk={'half'}
            fontSizeDesk={desktopVW(16)}
            fontSize={mobileVW(16)}
            validation={'onlyLetters'}
            setContainErrors={handleContainerrors}
          />
        </div>
    </Wrapper>
    <OverflowHidden>
        <animated.div
          style={{...buttonSpring}}
        >
          <Button
            text={loading ? dictionary?.loading : dictionary?.send}
            Type='submit'
            AriaLabel={'Send'}
            width='100%'
            widthDesk='100%'
            colorBackground={'black'}
            color="white"
            disableIt={containErrors}
          />
        </animated.div>
        <ResponseMsg
        style={{...responseSpring}}
        >
          <p>
            {text}
          </p>
        </ResponseMsg>
      </OverflowHidden>
    </form>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: ${colors.lightGreyButLighter};
  display: grid;
  grid-template-areas: "firstName" "lastName" "emailAddress" "address" "zipCode" "city" "phone" "country";
  grid-template-columns: 1fr;
  grid-template-rows: ${`repeat(8, ${mobileVW(70)})`};
  grid-gap: ${mobileVW(2)};
  margin-top: ${mobileVW(30)};

  .centerContent{
    position: relative;
    display: flex;
    align-items: center;
    
  }

  .newAddress__firstName{
    grid-area: firstName;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }

  .newAddress__lastName{
    grid-area: lastName;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  .newAddress__emailAddress{
    grid-area: emailAddress;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  .newAddress__address{
    grid-area: address;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  .newAddress__zipCode{
    grid-area: zipCode;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  .newAddress__city{
    grid-area: city;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  .newAddress__phone{
    grid-area: phone;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  .newAddress__country{
    grid-area: country;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
  
  ${desktopBreakpoint}{
    height: auto;
    grid-template-areas: "firstName lastName" "emailAddress emailAddress" "address address" "zipCode city" "phone country";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: ${`repeat(5, ${desktopVW(70)})`};
    grid-gap: ${desktopVW(2)};
    margin-top: 0;      
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  font-family: 'messinaMonoLight';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.subtleGrey};
  text-align: center;
  background-color: ${colors.black};
  color: ${colors.white};
  padding: ${mobileVW(30)} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${mobileVW(20)};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(14)};
    text-align: left;
    padding: ${desktopVW(25)} 0;
    margin-top: ${desktopVW(50)};
  }
`;

const OverflowHidden = styled.div`
  position: relative;
  overflow: hidden;
  ${desktopBreakpoint}{
    margin-top: ${desktopVW(30)};
  }
`;

const ResponseMsg = styled(animated.div)`
  transform: translateY(350%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  p{
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(14)};
    text-align: center;
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
    }
  }

  ${desktopBreakpoint}{
    top: ${desktopVW(20)};
  }
`;