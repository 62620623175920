import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex, colors } from '../../styles/index';
import {
  useCustomer,
  useLogoutCustomer,
  useUpdateCustomer,
  useGlobalDictionaryQuery,
} from '../../hooks';
import MyOrders from './accountSections/MyOrders';
import MyAddresses from './accountSections/MyAddresses';
import NewAddress from './accountSections/NewAddress';
import { useLocale } from '../../hooks/layout/useLocale';

export const Portal = () => {
  const user = useCustomer();
  const [logoutCustomer, { response, error }] = useLogoutCustomer();
  const [updateCustomer, state] = useUpdateCustomer();
  const [content, setContent] = useState('My Orders');
  const [addressesArray, setAddressesArray] = useState([]);
  const [newAddressMode, setNewAddressMode] = useState('new');
  const [addressToModify, setAddresstoModify] = useState(false);

  const dictionary = useGlobalDictionaryQuery();

  const locale = useLocale();

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await logoutCustomer(user);
    /* setTimeout(() => {
      navigate(`/${locale}/`);
    }, 1000) */
  };

  const handleShowNewAddress = () => {
    setNewAddressMode('new');
    setContent(dictionary?.newAddress);
  };

  const handleModifyAddress = addressData => {
    setAddresstoModify(addressData);
    setNewAddressMode('modify');
    setContent(dictionary?.newAddress);
  };

  const handleBackToAddress = () => setContent(dictionary?.myAddress);

  const handleUpdateCustomer = () => updateCustomer(user);

  useEffect(() => {
    if (user) {
      updateCustomer(user);
      if (user.addresses) setAddressesArray([...user?.addresses?.edges]);
    }
  }, []);

  useEffect(() => {
    if (state && state.response) {
      setAddressesArray([...state?.response?.customer?.addresses?.edges]);
    }
  }, [state]);

  useEffect(() => {
    if (response) {
      setTimeout(() => {
        navigate(`/${locale}`);
      }, 500);
    }
  }, [response]);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return;
    if (location.search.length > 0) {
      switch (location.search.substring(3)) {
        case 'my-orders':
          setContent(dictionary?.myOrders);
          break;
        case 'my-address':
          setContent(dictionary?.myAddress);
          break;
        case 'new-address':
          setContent(dictionary?.newAddress);
          break;
      }
    } else {
      setContent(dictionary?.myOrders);
    }
  }, [typeof window === 'object' ? location.search : null]);

  useEffect(() => {
    if (content) {
      switch (content) {
        case dictionary?.myOrders:
          navigate(`/${locale}/account/?=my-orders`);
          break;
        case dictionary?.myAddress:
          navigate(`/${locale}/account/?=my-address`);
          break;
        case dictionary?.newAddress:
          navigate(`/${locale}/account/?=new-address`);
          break;
      }
    }
  }, [content]);

  const contentMenu = [
    {
      title: dictionary?.myOrders,
      slug: 'My Orders',
      component: <MyOrders key="My Orders" user={user} />,
    },
    {
      title: dictionary?.myAddress,
      slug: 'My Address',
      component: (
        <MyAddresses
          key="My Address"
          handleShowNewAddress={() => handleShowNewAddress()}
          user={user}
          handleUpdateCustomer={() => handleUpdateCustomer()}
          addressesArray={addressesArray}
          handleModifyAddress={handleModifyAddress}
        />
      ),
    },
    {
      title: dictionary?.newAddress,
      slug: 'New Address',
      component: (
        <NewAddress
          key="New Address"
          handleBackToAddress={() => handleBackToAddress()}
          handleUpdateCustomer={() => handleUpdateCustomer()}
          addressToModify={addressToModify}
          newAddressMode={newAddressMode}
        />
      ),
    },
  ];

  return (
    <>
      <AccountHeader>
        <h1>{dictionary.myAccount}</h1>
      </AccountHeader>
      <Wrapper>
        <Sidebar>
          <p>{`Hi ${user?.firstName}!`}</p>
          {contentMenu?.map(itm => {
            if (itm?.title === dictionary?.newAddress) return false;
            return (
              <SidebarButton
                key={itm?.title}
                type="button"
                aria-label={itm?.title}
                onClick={() => setContent(itm?.slug)}
              >
                {itm?.title}
              </SidebarButton>
            );
          })}
          <LogoutButton type="button" ariaLabel="log out" onClick={e => handleLogout(e)}>
            {dictionary?.logOut}
          </LogoutButton>
        </Sidebar>
        <Content>{contentMenu?.find(itm => itm?.slug === content)?.component}</Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${desktopBreakpoint} {
    flex-direction: row;
  }
`;

const AccountHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${mobileVW(110)};
  padding-bottom: ${mobileVW(40)};

  h1 {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-weight: 300;
    color: ${colors.black};
    font-size: ${mobileVW(70)};
    text-transform: uppercase;
    text-align: center;
    line-height: 90%;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(165)};
      transform: translateY(0%);
    }
  }

  ${desktopBreakpoint} {
    padding-bottom: ${desktopVW(65)};
    padding-top: ${desktopVW(145)};
  }
`;

const Sidebar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  padding: ${mobileVW(30)} 0;

  p {
    display: block;
    font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    width: 100%;
    text-align: center;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      line-height: 80%;
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
      text-align: left;
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    border-top: none;
    border-bottom: none;
    width: 37.5vw;
    padding: 0 0 0 ${desktopVW(120)};
  }
`;

const SidebarButton = styled.button`
  display: block;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(35)};
  color: ${colors.black};
  line-height: 110%;
  font-weight: 700;
  width: 100vw;
  padding: 0;
  text-align: center;

  ${desktopBreakpoint} {
    line-height: 105%;
    width: auto;
    font-size: ${desktopVW(30)};
    text-align: left;
  }
`;

const LogoutButton = styled.button`
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  text-align: center;
  font-size: ${mobileVW(16)};
  line-height: 80%;
  padding: ${mobileVW(30)} 0 ${mobileVW(10)} 0;

  ${desktopBreakpoint} {
    font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    color: ${colors.subtleGrey};
    font-size: ${desktopVW(14)};
    line-height: 80%;
    padding: ${desktopVW(40)} 0;
    text-align: left;
  }
`;

const Content = styled.div`
  width: 100%;
  border-left: ${desktopVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
  }
`;
