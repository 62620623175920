const formatDate = (date: string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let dateArr = date.split('-')

  return `${dateArr[2].split('T')[0]} ${monthNames[parseInt(dateArr[1], 10) - 1]} ${dateArr[0]}`
}

export default formatDate