import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { decode } from 'shopify-gid';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../../styles/index';
import formatDate from '../../../utils/formatDate';
import formatPrice from '../../../utils/formatPrice';

interface Props {
  order: {};
  openReturnForm: () => void;
  closeReturnForm: () => void;
  setToOrderReturn: () => void;
}

export default function OrderCard(props: Props) {
  const { order, openReturnForm, closeReturnForm, setToOrderReturn } = props;

  return (
    <OrderCardWrapper>
      <OrderCardHeader>
        <OrderCardHeaderText>
          <div className="orderCardHeaderTextWrapper">
            <p>Order Place</p>
            <p className="orderCardHeaderTextClear">{formatDate(order?.processedAt)}</p>
          </div>
        </OrderCardHeaderText>
        <OrderCardHeaderText>
          <div className="orderCardHeaderTextWrapper">
            <p>Order N°</p>
            <p className="orderCardHeaderTextClear">{order?.orderNumber}</p>
          </div>
        </OrderCardHeaderText>
        <OrderCardHeaderText>
          <div className="orderCardHeaderTextWrapper">
            <p>Total</p>
            <p className="orderCardHeaderTextClear">
              {formatPrice(Number(order?.totalPrice?.amount), '€')}
            </p>
          </div>
        </OrderCardHeaderText>
      </OrderCardHeader>
      <OrderCardContent>
        {order?.lineItems &&
          order?.lineItems?.edges?.map(({ node }, index) => (
            <OrderItemWrapper key={index} isLast={order?.lineItems?.edges?.length === index + 1}>
              <OrderItemImg>
                <img src={node?.variant?.image?.originalSrc} />
              </OrderItemImg>
              <OrderItemContent>
                <OrderItemTitle>
                  <p>{node?.title}</p>
                  <p className="OrderItemTitle__paddingTop">{node?.variant?.title}</p>
                </OrderItemTitle>
                <OrderItemPrice>
                  {formatPrice(Number(node?.variant?.price?.amount), '€')}
                </OrderItemPrice>
                <OrderItemQuantity>
                  <p className="OrderItemTitle__quantityTitle">Quantity</p>
                  <div className="OrderItemTitle__quantityQuantity">
                    <p>{node?.quantity}</p>
                  </div>
                </OrderItemQuantity>

                {order?.successfulFulfillments?.length > 0 ? (
                  <>
                    {order.financialStatus === 'REFUNDED' ? (
                      <OrderItemReturn>Refunded</OrderItemReturn>
                    ) : (
                      <OrderItemReturn
                        type="button"
                        aria-label={`Return ${node?.title} from Order`}
                        onClick={() => {
                          openReturnForm();
                          setToOrderReturn(order, node);
                        }}
                      >
                        Return
                      </OrderItemReturn>
                    )}
                  </>
                ) : null}
              </OrderItemContent>
            </OrderItemWrapper>
          ))}
      </OrderCardContent>
    </OrderCardWrapper>
  );
}

const OrderCardWrapper = styled.div`
  background-color: white;
  color: black;
  margin-bottom: ${mobileVW(50)};
  padding: 0 ${mobileVW(30)};

  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(50)};
    padding: 0;
  }
`;

const OrderCardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${desktopBreakpoint} {
    flex-direction: row;
    justify-content: space-between;
    padding: ${desktopVW(30)} ${desktopVW(50)};
    border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);
  }
`;

const OrderCardHeaderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${mobileVW(25)};
  border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);
  width: 100%;

  .orderCardHeaderTextWrapper {
    display: flex;
    flex-direction: row;
  }

  p {
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(12)};
    color: ${colors.black};
    padding: ${mobileVW(2)} 0;
    margin-right: ${mobileVW(15)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
      margin-right: ${desktopVW(20)};
    }
  }

  .orderCardHeaderTextClear {
    color: ${colors.subtleGrey};
    ${desktopBreakpoint} {
      margin-right: 0;
    }
  }

  ${desktopBreakpoint} {
    padding: 0;
    border-bottom: 0;
    width: auto;
  }
`;

const OrderCardContent = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(50)};
  }
`;

const OrderItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${mobileVW(187)};
  ${({ isLast }) =>
    isLast ? 'border-bottom: none;' : `border-bottom: ${mobileVW(2)} solid rgba(0, 0, 0, 0.1);`}
  padding: ${mobileVW(26)} 0;

  ${desktopBreakpoint}{
    height: ${desktopVW(210)};
    ${({ isLast }) =>
      isLast ? 'border-bottom: none;' : `border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);`}
    padding: ${desktopVW(30)} 0;
  }
`;

const OrderItemImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${mobileVW(135)};
  width: ${mobileVW(140)};
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(140)};
    height: 100%;
  }
`;

const OrderItemContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${desktopBreakpoint} {
  }
`;

const OrderItemTitle = styled.div`
  position: absolute;
  top: 0;
  left: ${mobileVW(15)};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.thatSpecificColorThatTheDesignerUsedJustInTwoP};
  display: flex;
  flex-direction: column;
  font-size: ${mobileVW(12)};
  width: 70%;

  .OrderItemTitle__paddingTop {
    color: ${colors.subtleGrey};
    padding-top: ${mobileVW(7)};
    ${desktopBreakpoint} {
      padding-top: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
    left: ${desktopVW(24)};
  }
`;

const OrderItemPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
  }
`;

const OrderItemQuantity = styled.div`
  position: absolute;
  bottom: 0;
  left: ${mobileVW(15)};
  display: flex;
  flex-direction: row;
  height: ${mobileVW(20)};

  .OrderItemTitle__quantityTitle {
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    height: 100%;
    text-align: center;
    color: ${colors.subtleGrey};
    font-size: ${mobileVW(12)};
    padding: ${mobileVW(2)} ${mobileVW(10)} 0 0;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(15)};
      padding: ${desktopVW(3)} ${desktopVW(15)} 0 0;
    }
  }

  .OrderItemTitle__quantityQuantity {
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    height: 100%;
    color: ${colors.subtleGrey};
    font-size: ${mobileVW(12)};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${mobileVW(2)} solid rgba(0, 0, 0, 0.1);
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(15)};
      border: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);
      padding: ${desktopVW(10)};
    }
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(27)};
    left: ${desktopVW(24)};
  }
`;

const OrderItemQuantityGrid = styled.div`
  display: grid;
  color: ${colors.subtleGrey};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  user-select: none;
  grid-template-columns: 30% 40% 30%;
  width: ${mobileVW(70)};
  border: ${mobileVW(1)} solid ${colors.lightGreyButLighter};

  .OrderItem__quantityItem {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${mobileVW(12)};
    color: ${colors.thatSpecificColorThatTheDesignerUsedJustInTwoP};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(11)};
    }
  }

  .OrderItem__gridLeft {
    border-right: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    cursor: pointer;
  }

  .OrderItem__gridRight {
    border-left: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    cursor: pointer;
  }

  ${desktopBreakpoint} {
    border: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    width: ${desktopVW(83)};
  }
`;

const OrderItemReturn = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
  }
`;

const ReturnFormWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint} {
  }
`;

const ReturnBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  ${desktopBreakpoint} {
  }
`;
