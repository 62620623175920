import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { navigate } from 'gatsby';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  SpacerDesktop,
  SpacerMobile,
  SpringConfig,
} from '../../../styles/index';
import InputForm from './InputForm';
import { useResetPasswordCustomer, useGlobalDictionaryQuery, useLocale } from '../../../hooks';
import LinkTool from '../../shared/LinkTool';

export default function ResetForm(props: { path: string; id?: string; token?: string }) {
  const [resetPassword, { response, loading, error }] = useResetPasswordCustomer();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [containErrors, SetContainErrors] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [text, setText] = useState('');

  const softReset = () => {
    setClicked(false);
  };
  const dictionary = useGlobalDictionaryQuery();
  const locale = useLocale();

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true);
    }
    if (!state) {
      SetContainErrors(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await resetPassword(password, confirmPassword, props.id, props.token);
  };

  useEffect(() => {
    if (error && !clicked) {
      setClicked(true);
      setText(error);
      setTimeout(() => {
        softReset();
      }, 5000);
    }
    if (response) {
      setClicked(true);
      setText(dictionary.allSetThanks);
      setTimeout(() => {
        navigate(`/${locale}/account/login`);
      }, 2000);
    }
  }, [response, error]);

  const buttonSpring = useSpring({
    transform: clicked ? 'translateY(120%)' : 'translateY(0%)',
    config: SpringConfig.microInteraction,
  });

  const responseSpring = useSpring({
    transform: clicked ? 'translateY(0%)' : 'translateY(350%)',
    config: SpringConfig.microInteraction,
  });

  return (
    <Wrapper onSubmit={e => handleSubmit(e)}>
      <PreTitle>{dictionary.activateYourAccount}</PreTitle>
      <Title>Password Reset</Title>
      <SpacerDesktop vertical sHeight={15} />
      <SpacerMobile vertical sHeight={20} />
      <PreTitle>Let's get you logged back in.</PreTitle>
      <SpacerDesktop vertical sHeight={35} />
      <SpacerMobile vertical sHeight={20} />
      <InputForm
        inputValue={password}
        inputSetValue={setPassword}
        inputName="password"
        inputType="password"
        required
        onChangecallback={e => setPassword(e.target.value)}
        placeholder={dictionary.password}
        color={colors.black}
        width="100%"
        widthDesk="100%"
        fontSizeDesk={desktopVW(16)}
        fontSize={mobileVW(16)}
        validation="noValidation"
        setContainErrors={handleContainerrors}
      />
      <SpacerDesktop vertical sHeight={20} />
      <SpacerMobile vertical sHeight={20} />
      <InputForm
        inputValue={confirmPassword}
        inputSetValue={setConfirmPassword}
        inputName="confirmPassword"
        inputType="password"
        required
        onChangecallback={e => setConfirmPassword(e.target.value)}
        placeholder={dictionary.confirmPassword}
        color={colors.black}
        width="100%"
        widthDesk="100%"
        fontSizeDesk={desktopVW(16)}
        fontSize={mobileVW(16)}
        validation="noValidation"
        setContainErrors={handleContainerrors}
      />
      <SpacerDesktop vertical sHeight={20} />
      <SpacerMobile vertical sHeight={20} />
      <OverflowHidden>
        <animated.div style={{ ...buttonSpring }}>
          {/* <Button
            text={loading ? dictionary?.loading : dictionary?.send}
            Type='submit'
            AriaLabel={'Send'}
            width='100%'
            widthDesk='100%'
            colorBackground={'black'}
            color="white"
            disableIt={containErrors}
          /> */}
          <BoxButton>
            <Button Type="submit" AriaLabel="Send" width="100%" widthDesk="100%">
              {loading ? dictionary?.loading : dictionary?.send}
            </Button>
          </BoxButton>
        </animated.div>
        <ResponseMsg style={{ ...responseSpring }}>
          <p>{text}</p>
        </ResponseMsg>
      </OverflowHidden>
      <LoginFooter>
        <p>
          <u>
            <LinkTool to="/account/login">{dictionary.loginIntoMyAccount}</LinkTool>
          </u>
        </p>
      </LoginFooter>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: auto;
  width: 90vw;
  top: 50vh;
  left: 50vw;
  z-index: ${zIndex.surface};
  background-color: ${colors.frappuccino};
  transform: translate(-50%, -50%);
  padding: ${mobileVW(46)} ${mobileVW(35)} ${mobileVW(50)} ${mobileVW(35)};
  h1 {
    font-family: 'messinaMono';
    font-size: ${mobileVW(30)};
    line-height: ${mobileVW(30)};
    padding: ${mobileVW(8)} 0 ${mobileVW(10)} 0;
  }
  ${desktopBreakpoint} {
    top: ${desktopVW(205)};
    left: ${desktopVW(862)};
    width: ${desktopVW(432)};
    padding: ${desktopVW(46)} ${desktopVW(60)} ${desktopVW(60)} ${desktopVW(60)};
    transform: translate(0%, 0%);
    h1 {
      font-size: ${desktopVW(35)};
      line-height: ${desktopVW(35)};
      padding: ${desktopVW(8)} 0 ${desktopVW(10)} 0;
    }
  }
`;

const OverflowHidden = styled.div`
  position: relative;
  overflow: hidden;
  ${desktopBreakpoint} {
  }
`;

const BoxButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const Button = styled.button`
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: ${mobileVW(17)};
  font-family: 'messinaMono';
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    border-radius: ${mobileVW(2)};
    bottom: 0;
    left: 0;
    background: currentcolor;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(20)};
    &:after {
      border-radius: ${desktopVW(2)};
      height: ${desktopVW(2)};
    }
    &:hover::after {
      transform: scaleX(1);
    }
  }
`;

const ResponseMsg = styled(animated.div)`
  transform: translateY(350%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  p {
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(14)};
    text-align: center;
    line-height: 140%;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      margin-top: ${desktopVW(-12)};
    }
  }

  ${desktopBreakpoint} {
    top: ${desktopVW(20)};
  }
`;

const PreTitle = styled.p`
  font-family: 'messinaMonoLight';
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const Title = styled.p`
  font-family: 'messinaMonoLight';
  font-size: ${mobileVW(12)};
  color: ${colors.black};
  font-size: ${mobileVW(30)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(30)};
    line-height: 80%;
  }
`;

const LoginFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${mobileVW(30)};
  padding-top: ${mobileVW(20)};

  p {
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(12)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(12)};
      line-height: 80%;
      padding: 0 5px;
    }
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(35)};
    padding-top: ${desktopVW(25)};
  }
`;

//   const submitEmail = async e => {
//     e.preventDefault()
//     setLoading(true)
//     setTimeout(()=>{
//       loginCustomer(email, password).then(response =>{
//         console.log(response)
//         setClicked(true)
//         // if(response.success){
//         //   if(response.data.is_subscribed){
//         //     setText(emailSubscribeAlreadyInListMsg)
//         //   } else {
//         //     setText(emailSubscribeSuccessMsg)
//         //   }
//         //   setTimeout(()=>reset(), 5000)
//         // } else {
//         //   setText(emailSubscribeFailMsg)
//         //   setTimeout(()=>reset(), 5000)
//         // }
//       })
//     }, 500)
// }
