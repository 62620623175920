import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, desktopVW, mobileVW, DesktopView, MobileView, zIndex } from '../../styles/index'
import Image from 'gatsby-image'
import ActivateForm from './accountForms/ActivateForm'


export const Activate = ({ 
  path,
  loginBackgroundMobile,
  loginBackgroundDesktop,
  token,
  id,
}: { 
  path: string 
  loginBackgroundMobile: {
    title: string
    fluid: string
  }
  loginBackgroundDesktop: {
    title: string
    fluid: string
  }
  token: string
  id: string
}) => {


  return (
    <Wrapper>
      <MobileView>
        <BackgroundImage 
          fluid={loginBackgroundMobile.fluid}
          alt={loginBackgroundMobile.title}
        />
      </MobileView>
      <DesktopView>
        <BackgroundImage 
          fluid={loginBackgroundDesktop.fluid}   
          alt={loginBackgroundDesktop.title} 
        />
      </DesktopView>
      <ActivateForm 
        path={path}
        id={id}
        token={token}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100vh;
  z-index: ${zIndex.backgrounds};
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 50vw;
  top: 3vh;
  transform: translateX(-50%);
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    transform: translateX(0%);
    left: ${desktopVW(20)};
    top: ${desktopVW(30)};
  }
`;