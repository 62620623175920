import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useCustomer } from '../../hooks'
import { useLocale } from '../../hooks/layout/useLocale'

interface Props {
  component: React.ElementType
  path: string
}

const AuthWrapper = (props: Props) => {
  const { component: Component, path, ...rest } = props
  const [ready, setReady] = useState(false)
  const user = useCustomer()

  const locale = useLocale();

  useEffect(() => {
    if (!user) {
      typeof window !== "undefined" &&
        navigate(`${locale}/account/login`);
    }

    setReady(true)
  }, [0])

  return (
    <div>
      {ready ? (
        <Component path={path} {...rest} />
      ) : (
        <span />
      )}
    </div>
  );
}

export default AuthWrapper
