import React from 'react'
import styled from 'styled-components'
import { useGlobalDictionaryQuery } from '../../../hooks'
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../../styles/index'
import NewAddressForm from '../accountForms/NewAddressForm'

interface Props {
  handleBackToAddress: ()=>void
  handleUpdateCustomer: ()=>void
  addressToModify: {}
  newAddressMode: string
}

export default function NewAddress(props: Props) {

  const {
    handleBackToAddress,
    handleUpdateCustomer,
    addressToModify,
    newAddressMode,
  } = props

  const dictionary = useGlobalDictionaryQuery();

  return (
    <Wrapper>
      <SectionHeader>
        <Text>
          <h2>
            {dictionary.newAddress}
          </h2>
          <p>
            Please fill here your information.
          </p>
        </Text>
        <MyAddressButton
          onClick={()=>handleBackToAddress()}
        >
          back to address
        </MyAddressButton>
      </SectionHeader>
      <SectionContent>
        <NewAddressForm 
          handleBackToAddress={()=>handleBackToAddress()}
          handleUpdateCustomer={()=>handleUpdateCustomer()}
          newAddressMode={newAddressMode}
          addressToModify={addressToModify}
        />
      </SectionContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${mobileVW(30)};

  ${desktopBreakpoint}{
    padding: 0 ${desktopVW(120)} 0 ${desktopVW(60)};
  }
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${desktopBreakpoint}{
    flex-direction: row;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(35)};
    color: ${colors.black};
    line-height: 110%; 
    font-weight: 700;
    padding: ${mobileVW(30)} 0 0 0;
    text-align: center;

    ${desktopBreakpoint}{
      line-height: 105%; 
      font-size: ${desktopVW(30)};
      padding: 0 0 ${desktopVW(19)} 0;
      text-align: left;
    }
  }

  p{
    display: block;
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    width: 100%;
    text-align: center;
    padding: ${mobileVW(30)} ${mobileVW(40)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
      text-align: left;
    }
  }

  ${desktopBreakpoint}{
  
  }
`;

const MyAddressButton = styled.button`
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    text-align: center;
    background-color: ${colors.black};
    color: ${colors.white};
    width: 100%;
    padding: ${mobileVW(30)} 0;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      padding: ${desktopVW(25)} ${desktopVW(80)};
      width: ${desktopVW(300)};
    }

`;

const SectionContent = styled.div`
  width: 100%;

  ${desktopBreakpoint}{
    padding-top: ${desktopVW(50)};
  }
`;