import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../../styles/index'
import { useCustomerAddress } from '../../../hooks/user/useCustomerAddress'
import { navigate } from 'gatsby'
import { useGlobalDictionaryQuery } from '../../../hooks'


interface Props {
  handleShowNewAddress: ()=>void
  handleUpdateCustomer: ()=>void
  user: {}
  addressesArray: []
  handleModifyAddress: ()=>void
}

export default function MyAddresses(props: Props) {

  const {
    handleShowNewAddress,
    handleUpdateCustomer,
    user,
    addressesArray,
    handleModifyAddress,
  } = props

  const [
    { updateDefaultAddress, deleteAddress },
    { response, loading, error },
  ] = useCustomerAddress()

  console.log(user)
  const dictionary = useGlobalDictionaryQuery();

  const AddressCard = props =>{
    
    const {
      address
    } = props

    const [loadingRemove, setLoadingRemove] = useState(false)

    const handleRemove = () => {
      const isClient = typeof window === 'object'
      if (!isClient) return false
      setLoadingRemove(true)
      deleteAddress(user.token, address.id).then(()=>{
        handleUpdateCustomer()
      })
    }

    return (
      <AddressCardWrapper>
        <p>{`${address.firstName} ${address.lastName}`}</p>
        <p>{`${address.address1}`}</p>
        <p>{`${address.city} ${address.zip}`}</p>
        <p>{`${address.country}`}</p>
        <AddressCardFooter>
          <button
            type="button"
            aria-label="edit"
            onClick={()=> handleModifyAddress(address)}
          >
            edit
          </button>
          <button
            type="button"
            aria-label="delete"
            onClick={()=>handleRemove()}
          >
            delete
          </button>
        </AddressCardFooter>
      </AddressCardWrapper>
    )
  }

  return (
    <Wrapper>
      <SectionHeader>
        <Text>
          <h2>
            {dictionary.myAddress}
          </h2>
          <p>
            {dictionary.previousAndCurrentAddress}
          </p>
        </Text>
        <NewAddressButton
          onClick={()=>handleShowNewAddress()}
        >
          {dictionary.newAddress}
        </NewAddressButton>
      </SectionHeader>
      <SectionContent>
        {
          addressesArray.length > 0
          ?
          (
            addressesArray.map(({node})=>(
              <AddressCard
                key={node.id}
                address={node}
              />
            ))
          )
          :
          <p>
            {dictionary.noAddressSavedYet}
            <span
              className="myAddress__addNewAddressSpan"
              onClick={()=>handleShowNewAddress()}
            >
              {dictionary.addNewAddressHere}
            </span>
          </p>
        }
      </SectionContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${mobileVW(30)};

  ${desktopBreakpoint}{
    padding: 0 ${desktopVW(120)} 0 ${desktopVW(60)};
  }
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${desktopBreakpoint}{
    flex-direction: row;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(35)};
    color: ${colors.black};
    line-height: 110%; 
    font-weight: 700;
    padding: ${mobileVW(30)} 0 0 0;
    text-align: center;

    ${desktopBreakpoint}{
      line-height: 105%; 
      font-size: ${desktopVW(30)};
      padding: 0 0 ${desktopVW(19)} 0;
      text-align: left;
    }
  }

  p{
    display: block;
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    width: 100%;
    text-align: center;
    padding: ${mobileVW(30)} ${mobileVW(40)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
      text-align: left;
    }
  }

  ${desktopBreakpoint}{
  
  }
`;

const NewAddressButton = styled.button`
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    text-align: center;
    background-color: ${colors.black};
    color: ${colors.white};
    width: 100%;
    padding: ${mobileVW(30)} 0;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      padding: ${desktopVW(25)} ${desktopVW(80)};
      width: ${desktopVW(300)};
    }

`;

const SectionContent = styled.div`
  width: ${desktopVW(840)};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: ${mobileVW(30)};

  p{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(35)};
    color: ${colors.black};
    line-height: 110%; 
    font-weight: 700;
    padding: ${mobileVW(30)} 0 0 0;
    text-align: center;

    ${desktopBreakpoint}{
      line-height: 105%; 
      font-size: ${desktopVW(20)};
      padding: ${desktopVW(70)} 0 ${desktopVW(170)} 0;
      text-align: left;
    }
  }

  .myAddress__addNewAddressSpan{
    cursor: pointer;
    text-decoration: underline;
    padding-left: 1ch;
  }

  ${desktopBreakpoint}{
    padding-top: ${desktopVW(66)};
    flex-direction: row;
  }
`;

const AddressCardWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: ${mobileVW(353)};
  padding: ${mobileVW(40)};
  margin-right: 0;
  margin-bottom: ${mobileVW(20)};

  p{
    display: block;
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    width: 100%;
    padding: ${mobileVW(2)} 0;
    text-align: left;

    ${desktopBreakpoint}{
      width: ${desktopVW(220)};
      font-size: ${desktopVW(14)};
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
    }
  }

  button{
    display: inline;
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.black};
    text-align: left;
    padding: ${mobileVW(5)} 0;
    margin-right: ${mobileVW(20)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(6)} 0 ;
      margin-right: ${desktopVW(20)};

    }
  }

  ${desktopBreakpoint}{
    height: auto;
    width: ${desktopVW(303)};
    margin-right: ${desktopVW(62)};
    margin-bottom: ${desktopVW(20)};
    padding: ${desktopVW(55)};
  }
`;

const AddressCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${mobileVW(140)};

  ${desktopBreakpoint}{
    padding-top: ${desktopVW(70)};
  }
`;